import '../styles/pages/course.scss'
import React, {useEffect, useMemo, useState} from 'react';
import CourseService, {CourseModel} from "../services/courseService";
import {BigLogoImage, LoadingGif} from "../images";
import CourseOverview from "../components/CourseOverview";
import {ApiComponentType} from "../types/ApiComponent";
import CourseFaqList from "../components/CourseFaqList";
import CourseThemeList from "../components/CourseThemeList";
import {HeadFC, Link} from "gatsby";
import CourseFitIfList from "../components/CourseFitIfList";
import CourseAuthorsList from "../components/CourseAuthorsList";
import useAnalytics from "../hooks/useAnalytics";

type Props = {
    id: number
}

const CoursePage = ({id}: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);
    const [course, setCourse] = useState<CourseModel | null>(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        CourseService.getCourseById(id).then((response) => {
            setCourse(response.data.data)
        }).catch().finally(() => {
            setLoading(false)
        })
    }, [id]);

    const renderContent = useMemo(() => {
        if (course?.attributes?.Content) {
           return course.attributes.Content.map(componentItem => {
                switch (componentItem.__component) {
                    case ApiComponentType.FAQ_LIST: {
                        return (
                            <CourseFaqList data={componentItem?.faqList || []} />
                        )
                    }
                    case ApiComponentType.AUTHORS_LIST: {
                        return (
                            <CourseAuthorsList data={componentItem?.authors || []} />
                        )
                    }
                    case ApiComponentType.THEMES_LIST: {
                        return (
                            <CourseThemeList data={[...componentItem?.themesList] || []} />
                        )
                    }

                    case ApiComponentType.FIT_IF_LIST: {
                        return (
                            <CourseFitIfList data={componentItem?.fitIfBlocks || []} />
                        )
                    }
                }
           })
        }

        return (<div></div>)
    }, [course?.attributes.Content])


    return (
        <div className="container">
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="courseHeader">
                <Link to="/">
                    <img className="courseLogo" src={BigLogoImage} alt=""/>
                </Link>
            </div>

            <CourseOverview title={course?.attributes?.Course_Name} description={course?.attributes?.Description}/>
            {renderContent}

            <div className="courseFooter">
                <div className="courseFooterLogo">
                    <img src={BigLogoImage} width={180} alt=""/>
                </div>
                <div className="courseFooterCopy">
                    &copy; AEER PLATFORM INC
                </div>
                <address className="courseFooterAddress">
                    8 THE GREEN STE A DOVER, DE 19901
                </address>
            </div>
        </div>
    )
}

export default CoursePage;

export const Head: HeadFC = () => (
    <>
        <title>Course - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
