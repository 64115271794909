import './styles.scss';
import React, {useMemo} from 'react';
import {ThemeComponent} from "../../types/ApiComponent";
import CourseThemeItem from "./CourseThemeItem";

type Props = {
    data: ThemeComponent[];
}

const CourseThemeList = ({data = []}: Props) => {
    const renderList = useMemo(() => {
        return data.map(item => {
            return (
                <CourseThemeItem key={`item-${item.id}`} data={item} />
            )
        })
    }, [data])

    return (
        <div className="courseThemes">
            <div className="courseThemesTitle">
                Course topic
            </div>
            <div className="courseThemesList">
                {renderList}
            </div>
        </div>
    )
}

export default CourseThemeList;
