import './styles.scss';

import React, {useEffect, useMemo} from 'react';
import {FaqComponent} from "../../types/ApiComponent";
import CourseFaqItem from "./CourseFaqItem";
import {CrownImage} from "../../images";
import {Link} from "gatsby";

type Props = {
    data: FaqComponent[]
}

const CourseFaqList = ({ data }: Props) => {
    const renderList = useMemo(() => {
        return data.map(item => {
            return (
                <CourseFaqItem key={`item-${item.id}`} data={item} />
            )
        })
    }, [data])

    return (
        <div className="faqList">
            <div className="faqListTitle">Course Overview</div>
            <div className="faqListBody">
                {renderList}
            </div>
            <div className="faqListBtn">
                <Link to="/payment" className="btn btn-primary">
                    <img src={CrownImage} alt="" className="btn-img"/>
                    Sign up for FREE 7-day trial
                </Link>
            </div>
        </div>
    )
}

export default CourseFaqList;
