import './styles.scss';
import React, {useMemo} from 'react';
import {AuthorComponent} from "../../types/ApiComponent";
import CourseAuthorItem from "./CourseAuthorItem";

type Props = {
    data?: AuthorComponent[];
}

function CourseAuthorsList({data = []}: Props) {
    const renderList = useMemo(() => {
        return data?.map(item => (
            <CourseAuthorItem key={`item-${item.id}`} data={item} />
        ))
    }, [data])

    return (
        <div className="courseAuthorsList">
            <div className="courseAuthorsListTitle">Program authors</div>

            {renderList}
        </div>
    )
}

export default CourseAuthorsList;
