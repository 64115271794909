import './styles.scss';

import React, {useState} from 'react';
import {FaqComponent} from "../../../types/ApiComponent";
import {DownArrowImage} from "../../../images";


type Props = {
    data: FaqComponent;
}


const CourseFaqItem = ({ data }: Props) => {
    const [isOpen, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!isOpen)
    }

    return (
        <div className={`faqItem ${isOpen ? 'isOpen' : ''}`}>
            <div className="faqItemTop">
                <div className="faqItemQuestion">{data?.Question || ''}</div>
                <button onClick={toggleOpen} className="faqItemBtn">
                    <img src={DownArrowImage} alt=""/>
                </button>
            </div>
            {isOpen && (
                <div className="faqItemBottom">
                    {data?.Answer || ''}
                </div>
            )}
        </div>
    )
}

export default CourseFaqItem;
