import './styles.scss';
import React from 'react';
import {BigFaceLogoImage, CrownImage} from "../../images";
import {Link} from "gatsby";

type Props = {
    title?: string;
    description?: string;
}

const CourseOverview = ({title = '', description = ''}: Props) => {
    return (
        <div className="courseOverview">
            <div className="courseOverviewLeft">
                <div className="courseOverviewTitle">{title}</div>
                <div className="courseOverviewDesc">{description}</div>
                <div className="courseOverviewBtn">
                    <Link to="/payment" className="btn btn-primary">
                        <img src={CrownImage} alt="" className="btn-img"/>
                        Sign up for FREE 7-day trial
                    </Link>
                </div>
            </div>
            <div className="courseOverviewRight">
                <img src={BigFaceLogoImage} className="courseOverviewImage" alt=""/>
            </div>
        </div>
    )
}

export default CourseOverview;
