import './styles.scss';
import React from 'react';
import {FitIfComponent} from "../../../types/ApiComponent";
import {WITH_API_BASE} from "../../../configs/appConfigs";

type Props = {
    data: FitIfComponent
}

const CourseFitIfItem = ({data}: Props) => {
    return (
        <div className="courseFitIfItem">
            <div className="courseFitIfItemImg">
                <img
                    height={data.Image.data.attributes.height}
                    width={data.Image.data.attributes.width} src={WITH_API_BASE(data.Image?.data?.attributes?.url)}
                     alt=""/>
            </div>
            <div className="courseFitIfItemDesc">
                {data?.Description || ''}
            </div>
        </div>
    )
}

export default CourseFitIfItem;
