import './styles.scss';
import React, {useMemo} from 'react';
import {AuthorComponent} from "../../../types/ApiComponent";
import {WITH_API_BASE} from "../../../configs/appConfigs";

type Props = {
    data: AuthorComponent;
}

function CourseAuthorItem({data}: Props) {
    return (
        <div className="courseAuthorItem">
            <div className="courseAuthorItemLft">
                {data.Image?.data &&
                    <img src={WITH_API_BASE(data.Image?.data.attributes.url)} width={data.Image.data.attributes.width}
                         height={data.Image.data.attributes.height} alt=""/>}
            </div>
            <div className="courseAuthorItemData">
                <div className="courseAuthorItemTitle">
                    {data?.Title || ''}
                </div>
                <div className="courseAuthorItemDesc">
                    {data?.Description || ''}
                </div>
            </div>
        </div>
    )
}

export default CourseAuthorItem;
