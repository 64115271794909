import './styles.scss';
import React from 'react';
import {ThemeComponent} from "../../../types/ApiComponent";
import {WITH_API_BASE} from "../../../configs/appConfigs";

type Props = {
    data: ThemeComponent
}

const CourseThemeItem = ({data}: Props) => {
    return (
        <div className="courseThemeItem">
            {data?.Image?.data &&
                <div className="courseThemeItemImg">
                    <img width={data?.Image?.data?.attributes?.width}
                         height={data?.Image?.data?.attributes?.height}
                         src={WITH_API_BASE(data?.Image?.data?.attributes?.url)} alt=""/>
                </div>
            }
            <div className="courseThemeItemTitle">
                {data?.Title || ''}
            </div>
            <div className="courseThemeItemDesc">
                {data?.Description || ''}
            </div>
        </div>
    )
}

export default CourseThemeItem;
