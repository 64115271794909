import {AxiosResponse} from "axios";
import {BaseApiResponse, BaseApiService} from "./baseApiService";
import {FaqListComponent, FitIfListComponent, ImageComponent, ThemesListComponent} from "../types/ApiComponent";
import {WITH_API_BASE} from "../configs/appConfigs";

export interface CourseLevel {
    id: number;
    attributes: {
        createdAt: string,
        updatedAt: string,
        publishedAt: string,
        Name: string;
    }
}

export interface CourseCategory {
    id: number;
    attributes: {
        createdAt: string,
        updatedAt: string,
        publishedAt: string,
        Name: string;
    }
}

export interface CourseModel {
    id: number;
    attributes: {
        createdAt: string,
        updatedAt: string,
        publishedAt: string,
        Slug?: string,
        Course_Name?: string,
        Description?: string,
        Duration?: string,
        Lessons?: string,
        LessonDuration?: string,
        course_categories?: {
            data: Array<{
                id: number
            }>
        },
        course_levels?: {
            data: Array<{
                id: number
            }>
        },
        Image?: {
            data: ImageComponent
        }
        Content?: (FitIfListComponent | ThemesListComponent | FaqListComponent)[]
    }
}

class CourseService extends BaseApiService {
    public static async getCourseById(id: number): Promise<AxiosResponse<BaseApiResponse<CourseModel>>> {
        return this.client().get(WITH_API_BASE(`/api/courses/${id}?populate=deep,5`));
    }

    public static async getCourseLevels(): Promise<AxiosResponse<BaseApiResponse<Array<CourseLevel>>>> {
        return this.client().get(WITH_API_BASE(`/api/course-levels`));
    }

    public static async getCourseCategories(): Promise<AxiosResponse<BaseApiResponse<Array<CourseCategory>>>> {
        return this.client().get(WITH_API_BASE(`/api/course-categories`));
    }

    public static async getList(): Promise<AxiosResponse<BaseApiResponse<Array<CourseModel>>>> {
        return this.client().get(WITH_API_BASE(`/api/courses?populate=deep,2`));
    }
}

export default CourseService;
