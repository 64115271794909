import './styles.scss';
import React, {useMemo} from 'react';
import {FitIfComponent} from "../../types/ApiComponent";
import CourseFitIfItem from "./CourseFitIfItem";

type Props = {
    data?: FitIfComponent[];
}

const CourseFitIfList = ({data = []}: Props) => {
    const renderList = useMemo(() => {
        return data?.map(item => (
            <CourseFitIfItem key={`item-${item.id}`} data={item} />
        ))
    }, [data])

    return (
        <div className="fitIf">
            <div className="fitIfTitle">The course is right for you if:</div>
            <div className="fitIfList">
                {renderList}
            </div>
        </div>
    )
}

export default CourseFitIfList;
